import logo from 'src/assets/images/logo.png';
import logoNew from 'src/assets/images/logo-new.png';
import chickenLogo from 'src/assets/images/chicken-logo.png';
import coinSaleLogo from 'src/assets/images/coin_sale_logo.png';
import cardIcon from 'src/assets/images/card-icon.png';
import terrain from 'src/assets/images/terrain.png';
import chicken from 'src/assets/images/chicken.png';
import metaMask from 'src/assets/images/metamask.svg';
import walletConnect from 'src/assets/images/walletconnect.svg';
import closeIcon from 'src/assets/images/close-icon.png';
import loader from 'src/assets/images/loader.gif';
import chickenThumbnail from 'src/assets/images/chicken-thumbnail.png';
import clockIcon from 'src/assets/images/clock-icon.png';
import chickenSmall from 'src/assets/images/chicken-sm.png';
import laurelLight from 'src/assets/images/laurel-wreath-light.png';
import laurelDark from 'src/assets/images/laurel-wreath-dark.png';
import laurelItems from 'src/assets/images/augusts-augur-items.png';
import termsChicken from 'src/assets/images/terms-chicken.png';
import fusion from 'src/assets/images/fusion.png';
import wearables from 'src/assets/images/wearables.png';

import icon1 from 'src/assets/images/icons/icon1.png';
import icon2 from 'src/assets/images/icons/icon2.png';
import icon3 from 'src/assets/images/icons/icon3.png';
import icon4 from 'src/assets/images/icons/icon4.png';
import icon5 from 'src/assets/images/icons/icon5.png';
import icon6 from 'src/assets/images/icons/icon6.png';
import icon7 from 'src/assets/images/icons/icon7.png';
import icon8 from 'src/assets/images/icons/icon8.png';
import icon9 from 'src/assets/images/icons/icon9.png';
import icon10 from 'src/assets/images/icons/icon10.png';
import icon11 from 'src/assets/images/icons/icon11.png';
import clockIcon2 from 'src/assets/images/icons/clock_icon.svg';
import prizeIcon from 'src/assets/images/icons/prize_icon.svg';
import openseaIcon from 'src/assets/images/icons/opensea.svg';
import henIcon from 'src/assets/images/icons/hen.svg';
import roosterIcon from 'src/assets/images/icons/rooster.svg';

import warning from 'src/assets/images/warning.png';
import twitterLogo from 'src/assets/images/twitter-logo.png';
import externalLink from 'src/assets/images/external-link.png';
import googleCalendarIcon from 'src/assets/images/icons/google_calendar_icon.svg';
import outlookIcon from 'src/assets/images/icons/outlook_icon.svg';
import officeIcon from 'src/assets/images/icons/office_icon.svg';

import discord from 'src/assets/images/discord.svg';
import discord2 from 'src/assets/images/discord-icon.png';
import twitter from 'src/assets/images/twitter.svg';
import telegram from 'src/assets/images/telegram.svg';
import weth from 'src/assets/images/weth.svg';
import jewels from 'src/assets/images/jewels.png';
import bawk from 'src/assets/images/bawk.png';

import chickIcon1 from 'src/assets/images/chick_icon1.png';
import chickIcon2 from 'src/assets/images/chick_icon2.png';
import tradePrefOn from 'src/assets/images/marketplace/trade-pref-on.png';
import tradePrefOff from 'src/assets/images/marketplace/trade-pref-off.png';
import tradePrefCustomOn from 'src/assets/images/marketplace/trade-pref-custom-on.png';
import tradePrefCustomOff from 'src/assets/images/marketplace/trade-pref-custom-off.png';
import tradeAnyOn from 'src/assets/images/marketplace/trade-any-on.png';
import tradeAnyOff from 'src/assets/images/marketplace/trade-any-off.png';
import noTradeOn from 'src/assets/images/marketplace/no-trade-on.png';
import noTradeOff from 'src/assets/images/marketplace/no-trade-off.png';
import offersReceives from 'src/assets/images/marketplace/offers-receives.png';

import walletIcon from 'src/assets/images/icons/wallet.svg';
import purchaseIcon from 'src/assets/images/icons/purchase.svg';
import dollarIcon from 'src/assets/images/icons/dollar.svg';
import xIcon from 'src/assets/images/icons/x.svg';
import discordIcon from 'src/assets/images/icons/discord.svg';
import bawkMenuIcon from 'src/assets/images/icons/bawk_menu.png';
import bawkMenuCloseIcon from 'src/assets/images/icons/bawk_menu_close.png';

import serum from 'src/assets/images/serum.png';

import addBtnActive from 'src/assets/images/fusion/add_btn_active.png';
import addBtnClicked from 'src/assets/images/fusion/add_btn_clicked.png';
import addBtnDisabled from 'src/assets/images/fusion/add_btn_disabled.png';
import fusionBtnActive from 'src/assets/images/fusion/fusion_btn_active.png';
import fusionBtnClicked from 'src/assets/images/fusion/fusion_btn_clicked.png';
import fusionBtnDisabled from 'src/assets/images/fusion/fusion_btn_disabled.png';
import serumBtnActive from 'src/assets/images/fusion/serum_btn_active.png';
import serumBtnClicked from 'src/assets/images/fusion/serum_btn_clicked.png';
import serumBtnDisabled from 'src/assets/images/fusion/serum_btn_disabled.png';
import traitBtnActive from 'src/assets/images/fusion/trait_btn_active.png';
import traitBtnClicked from 'src/assets/images/fusion/trait_btn_clicked.png';
import traitBtnDisabled from 'src/assets/images/fusion/trait_btn_disabled.png';
import viewChickenActive from 'src/assets/images/fusion/view_chicken_active.png';
import viewChickenDisabled from 'src/assets/images/fusion/view_chicken_disabled.png';

import defaultRoadMap from 'src/assets/images/road-map.png';

import progressDark from 'src/assets/images/progress/progress-dark.png';
import progressLight from 'src/assets/images/progress/progress-light.png';
import barGreen from 'src/assets/images/progress/bar-green.png';
import barPurple from 'src/assets/images/progress/bar-purple.png';
import barRed from 'src/assets/images/progress/bar-red.png';
import barYellow from 'src/assets/images/progress/bar-yellow.png';
import lineDark from 'src/assets/images/progress/line-dark.png';
import lineLight from 'src/assets/images/progress/line-light.png';

import ethIcon from 'src/assets/images/icons/eth.svg';
import usdtIcon from 'src/assets/images/icons/usdt.svg';
import usdcIcon from 'src/assets/images/icons/usdc.svg';
import notesIcon from 'src/assets/images/icons/notes.svg';
import bubbleIcon from 'src/assets/images/icons/bubble_icon.png';

import chickenToilet from 'src/assets/images/chicken_toilet.png';

import tokenomicsPie from 'src/assets/images/tokenomics/tokenomics_pie.svg';
import presaleMark from 'src/assets/images/tokenomics/presale_mark.svg';
import marketingMark from 'src/assets/images/tokenomics/marketing_mark.svg';
import communityMark from 'src/assets/images/tokenomics/community_mark.svg';
import exchangeMark from 'src/assets/images/tokenomics/exchange_mark.svg';
import rocket from 'src/assets/images/rocket.png';
import playIcon from 'src/assets/images/play.png';
import yourChickenLogo from 'src/assets/images/your-chicken-logo.png';
import getYourChicken1 from 'src/assets/images/get-your-chicken-1.png';
import getYourChicken2 from 'src/assets/images/get-your-chicken-2.png';
import loot from 'src/assets/images/loot.png';

import crossArrow from 'src/assets/images/home/cross_arrow.png';

import stakingLogo from 'src/assets/images/staking/staking-logo.png';
import checkRound from 'src/assets/images/check-round.png';

export const IMAGES_URL = {
  LOGO: logo,
  LOGO_NEW: logoNew,
  CHICKEN_LOGO: chickenLogo,
  COIN_SALE_LOGO: coinSaleLogo,
  CARDICON: cardIcon,
  TERRAIN: terrain,
  CHICKEN: chicken,
  METAMASK: metaMask,
  WALLETCONNECT: walletConnect,
  PLAY_ICON: playIcon,
  CLOSEICON: closeIcon,
  LOADER: loader,
  CHICKENTHUMBNAIL: chickenThumbnail,
  CLOCKICON: clockIcon,
  CHICKENSMALL: chickenSmall,
  TWITTER_LOGO: twitterLogo,
  EXTERNAL_LINK: externalLink,
  DISCORD: discord,
  DISCORD_ICON: discord2,
  TWITTER: twitter,
  TELEGRAM: telegram,
  LAUREL_LIGHT: laurelLight,
  LAUREL_DARK: laurelDark,
  LAUREL_ITEMS: laurelItems,
  FUSION_BANNER: fusion,
  WEARABLES: wearables,
  WETH: weth,
  JEWELS: jewels,
  BAWK: bawk,
  TERMS_CHICKEN: termsChicken,
  SERUM: serum,
  DEFAULT_ROAD_MAP: defaultRoadMap,
  TRADE: {
    TRADE_PREF_ON: tradePrefOn,
    TRADE_PREF_OFF: tradePrefOff,
    TRADE_PREF_CUSTOM_ON: tradePrefCustomOn,
    TRADE_PREF_CUSTOM_OFF: tradePrefCustomOff,
    TRADE_ANY_ON: tradeAnyOn,
    TRADE_ANY_OFF: tradeAnyOff,
    NO_TRADE_ON: noTradeOn,
    NO_TRADE_OFF: noTradeOff,
    OFFERS_RECEIVES: offersReceives,
  },
  ICONS: {
    ICON_1: icon1,
    ICON_2: icon2,
    ICON_3: icon3,
    ICON_4: icon4,
    ICON_5: icon5,
    ICON_6: icon6,
    ICON_7: icon7,
    ICON_8: icon8,
    ICON_9: icon9,
    ICON_10: icon10,
    ICON_11: icon11,
    CHICK_ICON_1: chickIcon1,
    CHICK_ICON_2: chickIcon2,
    ICON_WALLET: walletIcon,
    ICON_PURCHASE: purchaseIcon,
    ICON_DOLLAR: dollarIcon,
    WARNING_ICON: warning,
    ETH_ICON: ethIcon,
    USDT_ICON: usdtIcon,
    USDC_ICON: usdcIcon,
    NOTES_ICON: notesIcon,
    BUBBLE_ICON: bubbleIcon,
    PRIZE_ICON: prizeIcon,
    CLOCK_ICON: clockIcon2,
    GOOGLE_CALENDAR_ICON: googleCalendarIcon,
    OUTLOOK_ICON: outlookIcon,
    OFFICE_ICON: officeIcon,
    HEN_ICON: henIcon,
    ROOSTER_ICON: roosterIcon,
    X_ICON: xIcon,
    DISCORD_ICON: discordIcon,
    OPENSEA_ICON: openseaIcon,
    BAWK_MENU_ICON: bawkMenuIcon,
    BAWK_MENU_CLOSE_ICON: bawkMenuCloseIcon,
  },
  FUSION: {
    ADD_BUTTON_ACTIVE: addBtnActive,
    ADD_BUTTON_CLICKED: addBtnClicked,
    ADD_BUTTON_DISABLED: addBtnDisabled,
    FUSION_BUTTON_ACTIVE: fusionBtnActive,
    FUSION_BUTTON_CLICKED: fusionBtnClicked,
    FUSION_BUTTON_DISABLED: fusionBtnDisabled,
    SERUM_BUTTON_ACTIVE: serumBtnActive,
    SERUM_BUTTON_CLICKED: serumBtnClicked,
    SERUM_BUTTON_DISABLED: serumBtnDisabled,
    TRAIT_BUTTON_ACTIVE: traitBtnActive,
    TRAIT_BUTTON_CLICKED: traitBtnClicked,
    TRAIT_BUTTON_DISABLED: traitBtnDisabled,
    VIEW_CHICKEN_ACTIVE: viewChickenActive,
    VIEW_CHICKEN_DISABLED: viewChickenDisabled,
  },
  PROGRESS: {
    PROGRESS_DARK: progressDark,
    PROGRESS_LIGHT: progressLight,
    BAR_GREEN: barGreen,
    BAR_PURPLE: barPurple,
    BAR_RED: barRed,
    BAR_YELLOW: barYellow,
    LINE_DARK: lineDark,
    LINE_LIGHT: lineLight,
  },
  CHICKEN_TOILET: chickenToilet,
  TOKENOMICS_PIE: tokenomicsPie,
  PRESALE_MARK: presaleMark,
  MARKETING_MARK: marketingMark,
  COMMUNITY_MARK: communityMark,
  EXCHANGE_MARK: exchangeMark,
  ROCKET: rocket,
  LOOT: loot,
  YOUR_CHICKEN_LOGO: yourChickenLogo,
  GET_YOUR_CHICKEN_1: getYourChicken1,
  GET_YOUR_CHICKEN_2: getYourChicken2,

  CROSS_ARROW: crossArrow,
  CHECK_ROUND: checkRound,
  CHICKEN_STAKING: {
    STAKING_LOGO: stakingLogo,
  },
};
