import Web3 from 'web3';

import { COIN_PRESALE_CONTRACT } from 'src/abis';
import { IS_COIN_TEST_NET } from 'src/utils/config';
import { exponentialBackOff } from 'src/utils/exponentialBackoff';
import { AbiItem } from 'web3-utils';
import { EventsEnum } from 'src/events';
import { ChainId } from 'src/constant/chainId';
import ContractService from './baseContractService';
import { usdtContractService } from '.';

export class CoinPresaleContractService extends ContractService {
  public static readonly NUM_STAGES = 21;

  constructor() {
    super(
      IS_COIN_TEST_NET ? ChainId.Sepolia : ChainId.Ethereum,
      COIN_PRESALE_CONTRACT.address,
      COIN_PRESALE_CONTRACT.abi as AbiItem[],
    );

    ContractService.createContract(this.web3, this.contractAddress, this.abi);
  }

  // eslint-disable-next-line class-methods-use-this
  get web3() {
    return IS_COIN_TEST_NET
      ? ContractService.sepoliaWeb3
      : ContractService.ethWeb3;
  }

  async getCurrentStep() {
    const currentStep = async () => this.contract.methods.currentStep().call();
    const { result } = await exponentialBackOff(currentStep);

    return result;
  }

  async getPresaleInfo() {
    const currentStep = Number(await this.getCurrentStep());
    const nextStep = Math.min(
      currentStep + 1,
      CoinPresaleContractService.NUM_STAGES - 1,
    );

    const isNextStepValid = currentStep < nextStep;

    const aggregate = [
      this.contract.methods.getTotalPresaleAmount(),
      this.contract.methods.totalTokensSold(),
      this.contract.methods.totalSoldPrice(),
      this.contract.methods.ethBuyHelper(1),
      this.contract.methods.getCurrentPrice(),
      this.contract.methods.getCurrentStageGoal(),
      this.contract.methods.paused(),
    ];

    if (isNextStepValid) {
      aggregate.push(this.contract.methods.stageTokenPrices(nextStep));
    }

    const aggregateFunc = async () => this.ethMultiCallProvider.aggregate(aggregate);
    const { result } = await exponentialBackOff(aggregateFunc);

    return {
      currentStep,
      nextStep,
      isNextStepValid,
      totalPresaleAmount: Number(result[0]),
      totalTokensSold: Number(result[1]),
      totalSoldPrice: Number(Web3.utils.fromWei(result[2])),
      currentStepPriceETH: Number(Web3.utils.fromWei(result[3])),
      currentStepPrice: Number(Web3.utils.fromWei(result[4])),
      stageGoalPrice: Number(Web3.utils.fromWei(result[5])),
      paused: result[6],
      nextStepPrice: isNextStepValid
        ? Number(Web3.utils.fromWei(result[7]))
        : 0,
    };
  }

  async ethBuyHelper(amount: number) {
    const ethBuyHelperFunc = async () => this.contract.methods.ethBuyHelper(amount).call();
    const { result } = await exponentialBackOff(ethBuyHelperFunc);

    return result;
  }

  async usdtBuyHelper(amount: number) {
    const usdtBuyHelperFunc = async () => this.contract.methods.usdtBuyHelper(amount).call();
    const { result } = await exponentialBackOff(usdtBuyHelperFunc);

    return result;
  }

  async getUserDeposits(userWalletId: string) {
    const userDeposits = async () => this.contract.methods.userDeposits(userWalletId).call();
    const { result } = await exponentialBackOff(userDeposits);

    return Number(Web3.utils.fromWei(result));
  }

  async buyWithEth(userWalletId: string, amount: number) {
    const wei = await this.ethBuyHelper(amount);
    const method = this.contract.methods.buyWithEth(amount);
    const functionSignature = method.encodeABI();

    const { transactionHash } = await this.sendRawTransaction(
      userWalletId,
      this.contractAddress,
      functionSignature,
      wei,
    );

    this.emitTransactionMined(
      EventsEnum.CoinSaleTransactionMined,
      {
        transactionHash,
        amount,
      },
      this.web3,
    );
  }

  async buyWithUSDT(userWalletId: string, amount: number) {
    const tether = await this.usdtBuyHelper(amount);
    console.log(tether);
    await usdtContractService.checkApproval(
      userWalletId,
      this.contractAddress,
      tether,
    );

    const method = this.contract.methods.buyWithUSDT(amount);
    const functionSignature = method.encodeABI();

    const { transactionHash } = await this.sendRawTransaction(
      userWalletId,
      this.contractAddress,
      functionSignature,
    );

    this.emitTransactionMined(
      EventsEnum.CoinSaleTransactionMined,
      {
        transactionHash,
        amount,
      },
      this.web3,
    );
  }

  async getEthBalance(userWalletId: string) {
    const bawkBalanceFunc = async () => this.web3.eth.getBalance(userWalletId);
    const { result: bawkBalance } = await exponentialBackOff(bawkBalanceFunc);

    return bawkBalance;
  }
}
