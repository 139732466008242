/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';

import { IMAGES_URL } from 'src/constant/images';

import { useWalletContext } from 'src/contexts/walletProvider';
import { useThemeContext } from 'src/contexts/theme';
import { useLanguageContext } from 'src/contexts/language';

import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { LANGUAGES } from 'src/constant/languages';
import { useTranslation } from 'react-i18next';
import FlagIcon from 'src/assets/i18n/FlagIcon';
import { NEED_LOCALIZATION } from 'src/utils/config';
import { PricingSection } from 'src/components/pricingSection';
import { CaretIcon } from 'src/components/caret-icon';
import { ExternalLinkIcon } from 'src/components/external-link-icon';

import './styles.css';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from 'src/store/userWallet/selector';
import { MenuIcon } from 'src/components/menu-icon';
import { MenuCloseIcon } from 'src/components/menu-close-icon';

export function Header() {
  const navRef = useRef<any>();
  const [isOpenNav, setIsOpenNav] = useState(false);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const { onLogin, isReady } = useWalletContext();
  const { theme, themeColors } = useThemeContext();
  const { lang, onSetLanguage } = useLanguageContext();

  const [toggleContents, setToggleContents] = useState(null);
  const { t } = useTranslation('translation', { keyPrefix: 'PAGES.HEADER' });

  useEffect(() => {
    if (!lang) return;
    let language = LANGUAGES.find(({ code }) => lang === code);
    if (!language) language = { code: 'en', title: 'English', flag: 'us' };
    setToggleContents(
      <>
        <FlagIcon code={language.flag} />
      </>,
    );
  }, [lang]);

  const login = () => {
    setIsOpenNav(false);

    if (!isReady) {
      return;
    }

    onLogin();
  };

  const handleClickOutside = useCallback((event) => {
    if (!isOpenNav) {
      return;
    }

    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsOpenNav(false);
    }
  }, [isOpenNav]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <nav className={`navbar navbar-expand-xl navbar-${theme} mynav ${isLoggedIn ? 'loggedin' : ''}`}>
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={IMAGES_URL.LOGO} className="logo" alt="" width={141} height={52} />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setIsOpenNav(true)}
          >
            {isOpenNav ? <MenuCloseIcon color="#728EAC" /> : <MenuIcon color="#728EAC" />}
          </button>
          <div ref={navRef} className={`collapse navbar-collapse header-collapse ${isOpenNav ? 'open' : ''}`} id="navbar_menu">
            <ul className="navbar-nav navbar-right">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t('CHICKENS.TITLE')}
                  {' '}
                  <CaretIcon color={themeColors.navText} />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link className="dropdown-item" to="/user-profile" onClick={() => setIsOpenNav(false)}>
                    {t('CHICKENS.MY_CHECKENS')}
                  </Link>
                  <Link className="dropdown-item" to="/coops" onClick={() => setIsOpenNav(false)}>
                    {t('CHICKENS.MY_COOPS')}
                  </Link>
                  <Link className="dropdown-item" to="/wearables" onClick={() => setIsOpenNav(false)}>
                    {t('CHICKENS.WEARABLES')}
                  </Link>
                  <Link className="dropdown-item" to="/fusion" onClick={() => setIsOpenNav(false)}>
                    {t('FUSION.TITLE')}
                  </Link>
                  <Link className="dropdown-item" to="/serum-mint" onClick={() => setIsOpenNav(false)}>
                    Serum Mint
                  </Link>
                  <a
                    className="dropdown-item"
                    target="_blank"
                    href="https://bitlovin.gitbook.io/chicken-derby-whitepaper/fusion"
                    rel="noreferrer"
                    onClick={() => setIsOpenNav(false)}
                  >
                    <ExternalLinkIcon color={themeColors.navText} />
                    {t('FUSION.SCIENCE_OF_FUSION')}
                  </a>
                  <a
                    className="dropdown-item"
                    target="_blank"
                    href="https://opensea.io/collection/chicken-derby"
                    rel="noreferrer"
                    onClick={() => setIsOpenNav(false)}
                  >
                    <ExternalLinkIcon color={themeColors.navText} />
                    Buy Chickens
                  </a>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t('RACING.TITLE')}
                  {' '}
                  <CaretIcon color={themeColors.navText} />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link className="dropdown-item" to="/races/enter" onClick={() => setIsOpenNav(false)}>
                    {t('RACING.ENTER_RACE')}
                  </Link>
                  <Link className="dropdown-item" to="/races/incoming" onClick={() => setIsOpenNav(false)}>
                    Incoming
                  </Link>
                  <Link className="dropdown-item" to="/races/scheduled" onClick={() => setIsOpenNav(false)}>
                    {t('RACING.SCHEDULED_RACES')}
                  </Link>
                  <Link className="dropdown-item" to="/races/results" onClick={() => setIsOpenNav(false)}>
                    {t('RACING.RESULTS')}
                  </Link>
                  <Link className="dropdown-item" to="/races/leaderboard" onClick={() => setIsOpenNav(false)}>
                    Leaderboard
                  </Link>

                  {/* <Link className="dropdown-item" to="/races/tournaments" onClick={() => setIsOpenNav(false)}>
                    {t('RACING.TOURNAMENTS')}
                  </Link> */}
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link className="nav-link" to="/vault" onClick={() => setIsOpenNav(false)}>
                  Vault
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link className="nav-link" to="/crc" onClick={() => setIsOpenNav(false)}>
                  CRC
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  href="https://bitlovin.gitbook.io/chicken-derby-tutorial/"
                  rel="noreferrer"
                  onClick={() => setIsOpenNav(false)}
                >
                  ?
                </a>
              </li>

              <li className="nav-item dropdown">
                <Link className="nav-link green" to="/" onClick={() => setIsOpenNav(false)}>
                  Buy BAWK
                </Link>
              </li>

              {NEED_LOCALIZATION && (
                <li className="nav-item">
                  <Form>
                    <Dropdown
                      onSelect={(eventKey) => {
                        onSetLanguage(eventKey);
                      }}
                    >
                      <Dropdown.Toggle variant="" id="dropdown-flags" className="text-left">
                        {toggleContents}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {LANGUAGES.map(({ code, title, flag }) => (
                          <Dropdown.Item key={code} eventKey={code}>
                            <FlagIcon code={flag} />
                            {' '}
                            {title}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form>
                </li>
              )}
            </ul>
          </div>
          <PricingSection onHide={() => setIsOpenNav(false)} />
        </div>
      </nav>
    </>
  );
}
