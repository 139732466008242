import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { IMAGES_URL } from 'src/constant/images';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { isBasicAuthLoggedInSelector, loadingUserWalletSelector } from 'src/store/userWallet/selector';
import { basicLoginRequest } from 'src/store/userWallet/reducer';
import { Loading } from 'src/components/loading';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from './theme';

interface BasicAuthProps {
  isBasicLoggedIn: boolean
}

export const BasicAuthContext = createContext<BasicAuthProps | undefined>(
  undefined,
);

export const useBasicAuthContext = () => {
  const context = useContext(BasicAuthContext);

  return context;
};

export default function BasicAuthProvider(props: {
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const isBasicLoggedIn = useSelector(isBasicAuthLoggedInSelector);
  const loading = useSelector(loadingUserWalletSelector);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { theme } = useThemeContext();
  const { t } = useTranslation('translation', { keyPrefix: 'CONTEXT.BASIC_AUTH' });

  const onLogin = () => {
    if (!username) {
      toast.warn(t('REQUIRED_USERNAME'));
      return;
    }

    if (!password) {
      toast.warn(t('REQURIED_PASSWORD'));
      return;
    }

    dispatch(basicLoginRequest({ username, password }));
  };

  const value = useMemo(
    () => ({
      isBasicLoggedIn,
    }),
    [isBasicLoggedIn],
  );

  return (
    <BasicAuthContext.Provider value={value}>
      {!isBasicLoggedIn ? (
        <div className="basic-auth-container">
          <form
            className="basic-auth"
            onSubmit={((e) => {
              e.preventDefault();
            })}
          >
            <img src={IMAGES_URL.LOGO} alt="logo" className="logo" />
            <div>
              <label>{t('USER_NAME')}</label>
              <input
                type="text"
                className="form-control"
                minLength={3}
                value={username}
                onChange={(e) => setUsername(e.target.value.trim())}
              />
            </div>
            <div className="mt-2 mb-3">
              <label>{t('PASSWORD')}</label>
              <input
                type="password"
                className="form-control"
                minLength={3}
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
              />
            </div>

            <button className="btn btn-login" type="submit" onClick={onLogin}>{t('LOGIN')}</button>
          </form>
          {loading && (
            <div className="loading-main">
              <Loading size={200} />
            </div>
          )}
        </div>
      ) : <>{props.children}</>}
      <ToastContainer theme={theme} />
    </BasicAuthContext.Provider>
  );
}
